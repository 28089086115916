import React from "react";
import { Col, Row } from "styled-bootstrap-grid";

const DiscontinuedMessage = () => {
  return (
    <div className="discontinued-msg margin-top">
      <Row>
        <Col xs={12}>
          <div className="margin-bottom">
            <h2>
              De VDDN Marketing Portal is per 1 januari 2025 niet meer
              beschikbaar
            </h2>
            <p className="margin-bottom">U kunt contact opnemen met:</p>
          </div>
        </Col>
        <Col md={6}>
          <h4 className="short-line">
            Dealer toolkits, advertenties, hockey, One Drive
          </h4>
          <div className="margin-bottom">
            <span style={{ textDecoration: "underline" }}>MINCO</span>
            <br />
            Mariette van Dijk
            <br />
            055 - 356 44 74
            <br />
            06 - 26 75 22 74
            <br />
            <a href="mailto:mariette.vandijk@minco.nu">
              mariette.vandijk@minco.nu
            </a>
          </div>
        </Col>
        <Col md={6}>
          <h4 className="short-line">Ternair-systeem, selecties en mailings</h4>
          <div className="margin-bottom">
            Marko Milović
            <br />
            06 -25 56 18 53
            <br />{" "}
            <span className="smaller">
              (bereikbaar op werkdagen 09:00-12:00 uur)
            </span>
            <br />
            <a href="mailto:crm.vcnl@volvocars.com">crm.vcnl@volvocars.com</a>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DiscontinuedMessage;
