import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import routes, { publicRoutes } from "./routes";
import config from "./config";
import { HelmetProvider } from "react-helmet-async";
import { GridThemeProvider } from "styled-bootstrap-grid";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { ThemeProvider, DEFAULT_THEME } from "@zendeskgarden/react-theming";
import { selectTheme } from "./styles/theme";
import GlobalStyle from "./styles/globalStyles";
import SearchProvider from "./components/SearchProvider";
import ScrollToTop from "./components/ScrollToTop";
import { ToastProvider } from "@zendeskgarden/react-notifications";
import { AuthProvider, useAuth } from "./context/AuthContext";
import Login from "./components/pages/Login";
import LoaderOverlay from "./components/LoaderOverlay";
import MaintenancePage from "./components/MaintenancePage";
import isUnderMaintenance from "./utils/isUnderMaintenance";
import versionCheck from "./utils/versionCheck";
import { AppContextProvider } from "./context/AppContext";

const AppWithAuth = () => {
  const { user, isLoading: userIsLoading } = useAuth();
  return (
    <React.Fragment>
      {!!userIsLoading ? (
        <LoaderOverlay />
      ) : (
        <Routes>
          <Route path={"/login"} element={<Login />} />
          {publicRoutes.map(({ path, element: Element }, i) => (
            <Route key={i} path={path} element={<Element />} />
          ))}
          {routes.map(({ path, element: Element }, i) => (
            <Route
              key={i}
              path={path}
              element={!!user?.subject ? <Element /> : <Navigate to="/login" />}
            />
          ))}
        </Routes>
      )}
    </React.Fragment>
  );
};

const App = () => {
  const clientTheme = selectTheme(config.clientCode);
  versionCheck();

  return (
    <HelmetProvider>
      <ThemeProvider
        theme={{
          ...DEFAULT_THEME,
          colors: {
            ...DEFAULT_THEME.colors,
            primaryHue: clientTheme.accentColor,
            dangerHue: "red",
          },
        }}
      >
        <SCThemeProvider theme={{ ...clientTheme }}>
          <GridThemeProvider gridTheme={{ ...clientTheme.gridTheme }}>
            <ToastProvider zIndex={402}>
              <SearchProvider>
                <BrowserRouter>
                  <div className={`app-${config.clientCode}`}>
                    <GlobalStyle />
                    <ScrollToTop />
                    {isUnderMaintenance() ? (
                      <MaintenancePage />
                    ) : (
                      <AuthProvider>
                        <AppContextProvider>
                          <AppWithAuth />
                        </AppContextProvider>
                      </AuthProvider>
                    )}
                  </div>
                </BrowserRouter>
              </SearchProvider>
            </ToastProvider>
          </GridThemeProvider>
        </SCThemeProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
