import React from "react";
import { MaintenanceMessageContent } from "./MaintenanceMessage";
import { FcVlc } from "react-icons/fc";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { GiGears } from "react-icons/gi";
import { useLocation, Navigate } from "react-router-dom";
import { VOLVO } from "../constants";
import config from "../config";
import DiscontinuedMessageVolvo from "../content/volvo/DiscontinuedMessage";
import { SLogin } from "./pages/Login";
import { Col, Container, Row } from "styled-bootstrap-grid";
import Logo from "./Logo";
import img from "../assets/img/wireframe.png";

const SMaintenancePage = styled.div`
  text-align: center;
  display: flex;
  min-height: 90vh;
  .inner {
    width: 600px;
    margin: auto;
    max-width: 80%;
    &.lg {
      width: 900px;
    }
  }
  .main-icon {
    display: block;
    width: 250px;
    max-width: 70%;
    height: auto;
    margin: auto;
    margin-bottom: 0.5em;
  }
  .img-discontinued {
    width: 170px;
    max-width: 75%;
    margin: 2em auto;
    animation-iteration-count: 2;
  }
  .small-icon {
    display: block;
    width: 50px;
    height: auto;
    margin: auto;
  }
  .corner-icon {
    position: fixed;
    bottom: -6px;
    right: -6px;
    height: 200px;
    max-height: 20%;
    width: auto;
    color: rgb(0, 0, 0, 0.1);
  }
  .content-block {
    @media (min-width: 700px) {
      font-size: 130%;
    }
    color: ${(props) => props.theme.textOnBg};
    margin-bottom: 1.5em;
    .date-str {
      display: inline-block;
      font-size: 0.85em;
      font-weight: normal;
      opacity: 0.9;
      font-family: monospace;
    }
    .msg-wrap {
      display: block;
    }
  }
  .main-heading {
    margin-bottom: 0.3em;
    font-weight: 4 00;
    opacity: 0.97;
    line-height: 1.3;
    font-size: 1.6em;
    color: ${(props) => props.theme.textOnBg};
  }
  .discontinued-msg {
    text-align: left;
    p {
      font-size: 15px;
    }
    h4 {
      font-size: 15px;
      font-weight: 700;
    }
    .short-line {
      max-width: 250px;
    }
  }
`;

const MaintenancePage = () => {
  const { t } = useTranslation("common");
  const location = useLocation();

  if (location.pathname !== "/") {
    return <Navigate to="/" replace={true} />;
  }

  const isDiscontinued = config?.uiSettings?.isDiscontinued === true;

  return (
    <SMaintenancePage>
      {isDiscontinued ? (
        <SLogin>
          <Container>
            <div className="bg-overlay"></div>
            <div className="logo-container">
              <Logo withLink={false} />
            </div>

            <div className="login-inner">
              <Row>
                <Col md={5} className="img-col">
                  <div className="img-wrap">
                    <div className="side-img fadeInGrow">
                      <img src={img} alt="Welcome" />
                    </div>
                  </div>
                </Col>
                <Col md={7} className="text-col">
                  <div className="text-wrap">
                    <div className="text-block"></div>
                    {config.clientCode === VOLVO ? (
                      <DiscontinuedMessageVolvo />
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </SLogin>
      ) : (
        <div className={`inner ${isDiscontinued ? "lg" : ""}`}>
          <FcVlc className="main-icon fadeInGrow" />
          <div className="content-block text-on-bg fadeInDown">
            <h1 className="main-heading">
              {isDiscontinued ? null : t("ui.Maintenance-in-progress")}
            </h1>
            <MaintenanceMessageContent
              showStartDate={false}
              isDiscontinued={isDiscontinued}
            />
          </div>
        </div>
      )}
      <GiGears className="corner-icon" />
    </SMaintenancePage>
  );
};

export default MaintenancePage;
