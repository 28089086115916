// Client codes
export const TEST = "TEST";
export const ESSILORNL = "ESSNL";
export const ESSILORBE = "ESSBE";
export const VOLVO = "Volvo";
export const PORTALUK = "OPTICIANSUK";
export const OOGWERELDNL = "OOGWERELDNL";
export const OOGWERELDBE = "OOGWERELDBE";
export const TEMATICA = "TEMATICAES";
export const ACOUSTICSNL = "ACOUSTICSNL";
export const OMP = "BNFGROEP";
export const BIKENL = "BIKENL";
export const RODENSTOCKDE = "RSDE";
export const EYECARENL = "EYECARENL";
export const EYECAREBE = "EYECAREBE";
export const EGSDE = "EGSDE";

// Language codes
export const [NL, VL, FR, DE, EN, ES] = [
  "nl-NL",
  "nl-BE",
  "fr-FR",
  "de-DE",
  "en-GB",
  "es-ES",
];

export const VALIDATIONPATTERNS = {
  phonePattern: /^[0-9 ]+$/i,
  urlPattern: /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/i,
  emailPattern:
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i,
};

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// Persons
export const PERSONSGENDERS = {
  UNKNOWN: "unknown",
  MALE: "Male",
  FEMALE: "Female",
};
export const PERSONSROLES = {
  CONTACTPERSONCAMPAINGS: "Contactperson campaigns",
  MANAGER: "Manager",
  ACCOUNTING: "Accounting",
  CAMPAIGNLISTRECEPIENT: "CampaignList recipient",
  CONTACTPERSON: "Contact person",
  OWNER: "Owner",
  SECONDOWNDER: "Second owner",
};
export const PERSONSCONTACTTYPES = {
  EMAIL: "Email",
  TELEPHONE: "Telefoon nr.",
  LINKEDIN: "LinkedIn",
  FACEBOOK: "Facebook",
  INSTAGRAM: "Instagram",
  FAX: "Fax",
  OTHER: "Overig",
};

// Customer images
export const CUSTOMERIMAGES = {
  LOGO: "logo",
  TEAM: "team",
  ROUTE: "route",
  LOCATION: "location",
  ADVISOR: "advisor",
  PREMIUM: "premium",
};

export const ADDRESSTYPES = {
  INVOICE: "invoice",
  SHOP: "shop",
  POSTAL: "postal",
  DELIVERY: "delivery",
};

export const INHERIT = "INHERIT";

export const PENDING_CUSTOMER_APPROVAL = "pendingCustomerApproval";
export const PENDING_ONLY_CUSTOMER_APPROVAL = "pendingOnlyCustomerApproval";
export const DEFAULT_APPROVAL_FILTER = PENDING_ONLY_CUSTOMER_APPROVAL;

export const NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const SESSION_EXPIRED = "SESSION_EXPIRED";
export const UNAUTHORIZED = "UNAUTHORIZED";
