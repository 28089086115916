import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { BiChevronRight } from "react-icons/bi";
import { useCollapse } from "react-collapsed";
import { opacify } from "polished";

const CollapseTrigger = styled.button`
  background: transparent;
  color: ${(props) => props.theme.textColor};
  display: flex;
  width: 100%;
  padding: 0;
  justify-content: space-between;
  border-bottom: 1px solid rgb(0 0 0 / 8%);
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  transition: border-color 0.2s;
  text-align: left;
  h2,
  h3,
  h4,
  p {
    margin: 0;
  }
  .text-wrap {
    padding: 0.7em 0;
    @media (min-width: 520px) {
      padding: 0.7em 1.2em;
    }
    h1,
    h2,
    h3 {
      color: ${(props) => props.theme.textColor};
    }
    h4 {
      font-size: 1.05em;
      line-height: 1.8;
      font-weight: 500;
      color: ${(props) => props.theme.headingColor};
    }
    display: flex;
    align-items: center;
    @media (min-width: 520px) {
      margin-left: ${(props) => (props.isStepper ? "-3.5em" : 0)};
    }
  }
  .arrow-icon {
    margin-right: 0.35em;
    font-size: 1.5em;
    min-width: 1.5em;
    transition: transform 0.01s;
    &.expanded {
      transform: rotate(90deg);
    }
  }
  .header-icon {
    color: ${(props) => opacify(-0.5, props.theme.accentColor)};
    font-size: 1.3em;
    margin-right: 0.75em;
    @media (min-width: 520px) {
      margin-right: 1em;
    }
  }
  &:hover {
    border-color: rgb(0 0 0 / 20%);
  }
`;

const CollapseItem = styled.div`
  border-top: none;
  .acc-item-inner {
    padding: 1.2em;
    background: ${(props) =>
      props.theme?.customCollapseColor
        ? props.theme?.customCollapseColor
        : `rgb(0 0 0 / 5%)`};
    .acc-item-inner {
      background: rgb(255 255 255 / 50%);
      font-size: 1.05em;
      line-height: 1.8;
    }
  }
`;

const CollapsibleBlock = ({
  triggerIcon,
  triggerText,
  children,
  open,
  background,
  isStepper,
  disableClick = false,
}) => {
  const [isClicked, setIsClicked] = useState(false);

  const { getCollapseProps, getToggleProps, isExpanded, setExpanded } =
    useCollapse();
  children = children ? children : "...";

  useEffect(() => {
    if (open) {
      if ((!isStepper && !isClicked) || !!isStepper) {
        setExpanded(true);
      }
    } else {
      if (isStepper) {
        setExpanded(false);
      }
    }
    setIsClicked(true);
  }, [open, isStepper, isClicked, setExpanded]);

  return (
    <React.Fragment>
      <CollapseTrigger
        {...getToggleProps()}
        background={background}
        isStepper={isStepper}
        disabled={disableClick}
      >
        <div className="text-wrap">
          {triggerIcon && <span className="header-icon">{triggerIcon}</span>}
          {triggerText}
        </div>
        {!isStepper && (
          <BiChevronRight
            className={`arrow-icon ${isExpanded ? "expanded" : ""}`}
          />
        )}
      </CollapseTrigger>
      <CollapseItem {...getCollapseProps()}>
        <div className="acc-item-inner">{children}</div>
      </CollapseItem>
    </React.Fragment>
  );
};

CollapsibleBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  triggerText: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  open: PropTypes.bool,
  openOnLoad: PropTypes.bool,
  nested: PropTypes.bool,
};

export default CollapsibleBlock;
