import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Col } from "styled-bootstrap-grid";
import Badge from "../Badge";
import Card from "../Card";
import CardList from "../CardList";
import Error from "../Error";
import Link from "../Link";
import Loader from "../Loader";
import Layout from "../parts/Layout";
import Sidebar from "../parts/Sidebar";
import { GiFoldedPaper } from "react-icons/gi";
import { MdEditDocument } from "react-icons/md";
import styled from "styled-components";
import Breadcrumbs from "../parts/Breadcrumbs";
import ImageBox from "../ImageBox";
import config from "../../config";
import strToColor from "../../utils/strToColor";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { opacify } from "polished";
import useVariantsSearch from "../../utils/useVariantsSearch";
import PaginationFromHeader from "../PaginationFromHeader";
import apiConfig from "../../apiConfig";
import { useParams } from "react-router-dom";
import NoItems from "../NoItems";
import icons from "../../icons";
import SearchBox from "../SearchBox";
import { SearchContext } from "../SearchProvider";
import { RiArchiveStackFill } from "react-icons/ri";
import { BsEnvelopeAtFill } from "react-icons/bs";
import createProof from "../../utils/createProof";

const SVCard = styled(Card)`
  .search-wrap {
    width: 15em;
    max-width: 100%;
  }
  .header-left {
    flex-grow: 1;
  }
  .header-right {
    flex-grow: 0;
  }
  @media (max-width: 700px) {
    .card-header {
      flex-wrap: wrap;
    }
    .header-left {
      order: 2;
      padding-top: 0.8em;
      flex-grow: 1;
      width: 100%;
    }
    .header-right {
      flex-grow: 1;
      order: 1;
    }
    .search-wrap {
      width: auto;
    }
  }
`;

const SVariantsWrap = styled.div`
  .v-card {
    overflow: hidden;
    .card-header {
      background: white;
      .header-left {
        font-size: 80%;
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        max-width: 85%;
      }
      h2 {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .card-body {
      border-radius: 0;
      background: white;
    }
  }
  .preview-wrap {
    > div {
      border: 1px solid ${(props) => props.theme.borderColor};
    }
  }
  .card-footer {
    background: white;
  }
  .hover-link {
    .hover-link-inner {
      opacity: 0;
      transition: opacity 0.2s;
    }
    &:hover {
      .hover-link-inner {
        opacity: 0.6;
      }
    }
  }
  .card-footer-program {
    &::before,
    &::after {
      content: "";
      display: table;
      clear: both;
    }
    .img-wrap {
      float: left;
      margin-right: 1em;
      width: 42px;
      height: 42px;
      border: 1px solid ${(props) => props.theme.borderColor};
    }
    .text-wrap:not(:first-child) {
      padding-left: calc(42px + 1em);
    }
    h4 {
      line-height: 1.3;
      margin-top: 0.2em;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .no-items {
    min-height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn-link {
      margin-top: -1.7em;
    }
  }
  .all-approved {
    background: ${(props) => opacify(-0.5, props.theme.successColor)};
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 45vh;
    .icon {
      width: 150px;
      height: auto;
      display: inline-block;
      max-width: 40%;
      color: ${(props) => props.theme.successColor};
      margin-top: 2em;
    }
    h4 {
      color: #51a249;
      font-size: 1.3em;
      font-weight: 600;
    }
  }
`;

const VariantList = () => {
  const { t } = useTranslation("common");
  const { filter } = useParams();
  const { variantsFilter, setVariantsFilter } = useContext(SearchContext);

  const approvalFilter =
    filter === "approved"
      ? "customerApproved"
      : config.uiSettings.approvalFilter;

  // data
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 12;

  const {
    data: variantsWithHeaders,
    isLoading: variantsIsLoading,
    isError: variantsIsError,
  } = useVariantsSearch({
    page,
    pageSize: PAGE_SIZE,
    approvalFilter: approvalFilter,
    searchPhrase: variantsFilter,
  });

  const variants =
    !!variantsWithHeaders && !!variantsWithHeaders.data
      ? variantsWithHeaders.data
      : [];
  const headers =
    !!variantsWithHeaders && variantsWithHeaders.headers
      ? variantsWithHeaders.headers
      : null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    setPage(1);
    setVariantsFilter("");
  }, [filter, setVariantsFilter]);

  useEffect(() => {
    if (variantsWithHeaders?.data?.length) {
      const varsWithoutProof = variantsWithHeaders?.data.filter(
        (v) => v?.id && !v?.recentProofFileId
      );
      if (!varsWithoutProof?.length) {
        return;
      } else {
        const randomVarWithoutProof =
          varsWithoutProof[
            Math.floor(Math.random() * varsWithoutProof?.length)
          ];
        createProof({ id: randomVarWithoutProof?.id });
      }
    }
  }, [variantsWithHeaders?.data]);

  const pageTitle =
    filter === "approved"
      ? t("ui.Approved-variants")
      : t("ui.Variants-to-approve");
  const PageIcon = filter === "approved" ? RiArchiveStackFill : MdEditDocument;

  return (
    <Layout
      main={
        <SVCard
          title={pageTitle}
          shadow
          icon={<PageIcon />}
          headerRight={
            <div className="search-wrap">
              <SearchBox
                filter="variantsFilter"
                setFilter="setVariantsFilter"
              />
            </div>
          }
          className="variants-card"
        >
          <Breadcrumbs
            pageTitle={pageTitle}
            pathArray={[
              {
                title: t("ui.Variants"),
              },
            ]}
          />
          <SVariantsWrap>
            {!!variants &&
            variants.length === 0 &&
            +page === 1 &&
            !variantsIsLoading &&
            !variantsIsError ? (
              <Link
                to={
                  filter === "approved"
                    ? "/variants/to-approve"
                    : "/variants/approved"
                }
                className="no-decoration hover-link"
              >
                {filter === "approved" ? (
                  <div className="no-items">
                    <NoItems label={t("ui.No-variants-approved")}>
                      <p className="hover-link-inner btn-link">
                        {t("ui.View-variants-to-approve")} {icons.chevronRight}
                      </p>
                    </NoItems>
                  </div>
                ) : (
                  <div className="all-approved">
                    <IoCheckmarkCircleSharp className="icon fadeInGrow" />
                    <h4>{t("ui.All-variants-approved")}</h4>
                    <p className="hover-link-inner btn-link">
                      {t("ui.View")} {icons.chevronRight}
                    </p>
                  </div>
                )}
              </Link>
            ) : null}

            {!!variantsIsLoading ? (
              <Loader />
            ) : !!variantsIsError ? (
              <Error errorObj={variantsIsError} />
            ) : !!variants && !!variants.length ? (
              <CardList>
                {variants.map((v) => (
                  <Col lg={4} sm={6} key={v.id}>
                    <Link className="no-decoration" to={`/variant/${v.id}`}>
                      <Card
                        className="v-card"
                        isLink
                        title={<span title={v.name}>{v.name}</span>}
                        icon={
                          v.kind === "dm" ? (
                            <GiFoldedPaper />
                          ) : (
                            <BsEnvelopeAtFill />
                          )
                        }
                        // headerRight={
                        //   <Badge>{v.kind === "dm" ? "DM" : "eDM"}</Badge>
                        // }
                        footer={
                          <div className="card-footer-program">
                            {/* {v?.program?.thumbnailFileId?.length === 32 && (
                              <div className="img-wrap">
                                <ImageBox
                                  params={{
                                    Width: 40,
                                    Height: 40,
                                  }}
                                  id={v.program.thumbnailFileId}
                                  boxHeight="100%"
                                />
                              </div>
                            )} */}
                            <div className="text-wrap">
                              {!!v.program.theme && (
                                <Badge
                                  background={strToColor(
                                    v.program.theme,
                                    60,
                                    85
                                  )}
                                  className="inline-block"
                                >
                                  {v.program.theme}
                                </Badge>
                              )}
                              <h4>
                                {v.program.marketingName ||
                                  v.program.name ||
                                  "..."}
                              </h4>
                            </div>
                          </div>
                        }
                      >
                        <div className="preview-wrap">
                          <ImageBox
                            id={v.recentProofFileId}
                            api={apiConfig.proofs}
                            boxHeight="100%"
                            className="hover-zoom"
                            params={{
                              Format: "jpg",
                              Width: 350,
                              Height: 350,
                            }}
                          />
                        </div>
                      </Card>
                    </Link>
                  </Col>
                ))}
              </CardList>
            ) : null}

            {!!headers?.link ? (
              <div className="border-top padding-top">
                <PaginationFromHeader
                  current={page}
                  linkStr={headers.link}
                  setPage={setPage}
                />
              </div>
            ) : null}
          </SVariantsWrap>
        </SVCard>
      }
      sidebar={<Sidebar />}
      pageTitle={pageTitle}
    />
  );
};

export default VariantList;
