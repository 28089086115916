
import { desaturate, opacify } from "polished";
import config from "../config";
import {
  ESSILORNL,
  ESSILORBE,
  OOGWERELDNL,
  OOGWERELDBE,
  PORTALUK,
  TEMATICA,
  VOLVO,
  OMP,
  BIKENL,
  ACOUSTICSNL,
  RODENSTOCKDE,
  EYECARENL,
  EGSDE,
  TEST,
  EYECAREBE,
} from "../constants";

const theme = {
  background: "#ddd",
  borderRadius: "6px",
  borderColor: "#e5e5e5",
  lightBg: "#f4f4f4",
  mediumLightBg: "#e2e2e2",
  mainFont:
    "system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue',Arial,sans-serif",
  headingColor: "#4f5a60",
  textColor: "#1f3a48",
  lightTextColor: "rgb(255 255 255 / 90%)",
  accentColor: "#0e4f91",
  secondaryColor: "#acacac",
  successColor: "#5fbd56",
  errorColor: "#e84d4d",
  menuBreakpoint: "800px",
  textOnBg: "#4f5a60",
  linkOnBg: "#0e4f91",
  gridTheme: {
    row: {
      padding: 16,
    },
    col: {
      padding: 8,
    },
    container: {
      maxWidth: {
        md: 745,
      },
    },
  },
  gridThemeCondensed: {
    row: {
      padding: 6,
    },
    col: {
      padding: 3,
    },
    container: {
      maxWidth: {
        md: 745,
      },
    },
  },
};

export const themeEssilor = {
  ...theme,
  buttonColor: theme.accentColor,
  linkColor: theme.accentColor,
  linkOnBg: theme.accentColor,
  iconColor: opacify(-0.6, theme.accentColor),
  hoverColor: opacify(-0.9, theme.accentColor),
};

export const themeVolvo = {
  ...theme,
  accentColor: "#3c5c8e",
  buttonColor: "#3c5c8e",
  linkColor: "#3c5c8e",
  linkOnBg: "#3c5c8e",
  iconColor: opacify(-0.6, "#3c5c8e"),
  hoverColor: opacify(-0.9, "#3c5c8e"),
};

export const themeTematica = {
  ...theme,
  accentColor: "#f64b38",
  buttonColor: "#f64b38",
  background: "#162552",
  textOnBg: "#ccc",
  linkOnBg: "#ccc",
  linkColor: "#162552",
  iconColor: opacify(-0.1, "#f64b38"),
  hoverColor: opacify(-0.9, "#f64b38"),
  lightBg: "#ffffff",
  mediumLightBg: "#D3E4FF", //For selecta campagna as well
  secondaryColor: "#162552",
  calenderSelected: "#D3E4FF",
  calenderItems: "rgba(211, 228, 255, 0.25)",
  customCollapseColor: "rgba(211, 228, 255, 0.5)"
    
};

export const themePortalUK = {
  ...theme,
  accentColor: "#004160",
  buttonColor: "#004160",
  linkColor: "#004160",
  linkOnBg: "#004160",
  iconColor: opacify(-0.6, "#004160"),
  hoverColor: opacify(-0.9, "#004160"),
};

export const themeOogwereld = {
  ...theme,
  accentColor: "#2d98a7",
  buttonColor: "#2d98a7",
  secondaryColor: "#8caeb3",
  linkColor: "#2d98a7",
  linkOnBg: "#2d98a7",
  iconColor: opacify(-0.6, "#2d98a7"),
  hoverColor: opacify(-0.9, "#2d98a7"),
};

export const themeBikeNL = {
  ...theme,
  accentColor: "#4949fc",
  secondaryColor: "#637dbe",
  buttonColor: "#333333",
  background: "#51596d",
  headingColor: "#555555",
  textOnBg: "#ccc",
  linkOnBg: "#ccc",
  linkColor: "#333333",
  iconColor: opacify(-0.6, "#4949fc"),
  hoverColor: opacify(-0.9, "#4949fc"),
};

export const themeOMP = {
  ...theme,
  accentColor: "#f67251",
  buttonColor: "#df6445",
  background: "#5d6272",
  textOnBg: "#ccc",
  linkOnBg: "#ccc",
  linkColor: "#333333",
  iconColor: opacify(-0.1, "#f67251"),
  hoverColor: opacify(-0.9, "#f67251"),
};

export const themeAcousticsNL = {
  ...theme,
  accentColor: "#e29d00",
  buttonColor: "#434d52",
  linkColor: "#434d52",
  background: "#d9e0e8",
  linkOnBg: "#434d52",
  iconColor: opacify(-0.4, "#e29d00"),
  hoverColor: opacify(-0.9, desaturate(0.8, "#e29d00")),
};

export const themeEyecare = {
  ...theme,
  accentColor: "#444342",
  buttonColor: "#20201e",
  linkColor: "#4a6473",
  background: "#e8e8e8",
  linkOnBg: "#434d52",
  iconColor: opacify(-0.5, "#c4b3a8"),
  hoverColor: opacify(-0.8, "#c4b3a8"),
  secondaryColor: "#68645d",
};

export const selectTheme = (clientName) => {
  switch (clientName) {
    case TEST:
    case ESSILORNL:
    case ESSILORBE:
    case RODENSTOCKDE:
    case EGSDE:
      return themeEssilor;
    case VOLVO:
      return themeVolvo;
    case TEMATICA:
      return themeTematica;
    case OOGWERELDNL:
    case OOGWERELDBE:
      return themeOogwereld;
    case PORTALUK:
      return themePortalUK;
    case OMP:
      return themeOMP;
    case BIKENL:
      return themeBikeNL;
    case ACOUSTICSNL:
      return themeAcousticsNL;
    case EYECARENL:
    case EYECAREBE:
      return themeEyecare;
    default:
      return theme;
  }
};

export default selectTheme(config.clientCode);
