import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import icons from "../../icons";
import createProof from "../../utils/createProof";
import useVariant from "../../utils/useVariant";
import useVariantParams from "../../utils/useVariantParams";
import callVariantApproval from "../../utils/callVariantApproval";
import Badge from "../Badge";
import Card from "../Card";
import Error from "../Error";
import Loader from "../Loader";
import Notification from "../Notification";
import Layout from "../parts/Layout";
import VariantCarousel from "./VariantCarousel";
import { useSWRConfig } from "swr";
import Button from "../Button";
import { useToast } from "@zendeskgarden/react-notifications";
import styled from "styled-components";
import callVariantParams from "../../utils/callVariantParams";
import { GiFoldedPaper } from "react-icons/gi";
import Breadcrumbs from "../parts/Breadcrumbs";
import config from "../../config";
import { INHERIT } from "../../constants";
import EdmPreview from "./EdmPreview";
import Link from "../Link";
import { Col, Row } from "styled-bootstrap-grid";
import FAQList from "../FAQList";
import ContactText from "../ContactText";
import ImageBox from "../ImageBox";
import CycleIcon from "../CycleIcon";
import EditParamsModal from "./EditParamsModal";
import { BsEnvelopeAtFill } from "react-icons/bs";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";

const showCustomerApproval = config.uiSettings.showCustomerApproval;

const SCard = styled(Card)`
  @media (max-width: 550px) {
    .hide-btn-labels {
      .btn-label {
        display: none;
      }
      svg:first-child:not(:only-child) {
        margin-right: 0;
      }
    }
  }
`;

const SVariant = styled.div`
  .form-box {
    background: ${(props) => props.theme.borderColor};
    padding: 1em;
    margin-bottom: 1em;
  }
  .popup-half-container {
    display: block;
  }
  .iframe-container {
    min-height: 60vh;
    border: 1px solid ${(props) => props.theme.borderColor};
    cursor: default;
  }
  .carousel .slide iframe {
    width: 100%;
    margin: 0;
    display: block;
  }
  .carousel-wrapper {
    background: white;
    border: 1px solid ${(props) => props.theme.borderColor};
  }
  .edm-wrap {
    background: #e4e4e4;
    text-align: center;
    padding: 1.2em;
    margin: 0 -1.2em -1.2em;
    .edm-wrap-inner {
      background: white;
      margin: auto;
      width: 600px;
      max-width: 100%;
    }
  }
  .v-toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    flex-wrap: wrap;
    .bc-wrap {
      margin-bottom: 0.6em;
    }
    .text-wrap {
      display: flex;
      justify-content: space-between;
      .text-wrap-inner {
        margin-right: 2em;
      }
      strong {
        display: block;
      }
    }
    .btn-wrap {
      button:not(:first-child) {
        margin-left: 0.4em;
      }
    }
  }
  .error-wrap {
    background: white;
    margin: 0 -1.2em -1.2em;
  }
  @media (max-width: 900px) {
    .btn-wrap {
      width: 100%;
      button {
        width: calc(50% - 0.2em);
      }
    }
  }
  @media (max-width: 550px) {
    .btn-wrap {
      button {
        width: 100%;
      }
      button:not(:first-child) {
        margin-left: 0 !important;
        margin-top: 0.6em;
      }
    }
    .hide-btn-labels {
      .btn-label {
        display: none;
      }
    }
  }
`;

const SCampaignBlock = styled.div`
  &::before,
  &::after {
    content: "";
    display: table;
    clear: both;
  }
  .img-wrap {
    float: left;
    margin-right: 1em;
    width: 70px;
    height: 70px;
  }
  .text-wrap {
    padding-top: 0.1em;
    p {
      margin-top: -0.4em;
      margin-bottom: 0.1em;
    }
  }
  .text-wrap:not(:first-child) {
    padding-left: calc(70px + 1em);
  }
`;

const Variant = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const { addToast } = useToast();
  const { mutate } = useSWRConfig();

  const [proofs, setProofs] = useState([]);
  const [proofType, setProofType] = useState(null);
  const [proofsError, setProofsError] = useState(null);
  const [viewFullScreen, setViewFullScreen] = useState(false);
  const [flipPosition, setFlipPosition] = useState(0);

  const {
    data: variant,
    isLoading: variantIsLoading,
    isError: variantIsError,
    cacheKey: variantCacheKey,
  } = useVariant(id);

  const {
    data: variantParams,
    isLoading: variantParamsIsLoading,
    isError: variantParamsIsError,
    cacheKey: variantParamsCacheKey,
  } = useVariantParams(id);

  useEffect(() => {
    createProof({
      id: id,
      onSuccess: (res) => {
        setProofs(res.results);
        setProofsError(null);
        setProofType(res.type);
      },
      onError: (error) => {
        setProofs([]);
        setProofsError(error);
        setProofType(null);
      },
    });
  }, [id]);

  const updateVariant = () => mutate(variantCacheKey);

  const approveVariant = (id) => {
    callVariantApproval({
      id: id,
      onSuccess: () => {
        addToast(
          ({ close }) => (
            <Notification
              title={t("ui.Variant-approved")}
              text={t("ui.Pending-internal-approval")}
              type="success"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
        updateVariant();
      },
      onError: (error) => {
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-approve-variant")}
              errorText={
                !!error && !!error.length && !!error[0]
                  ? error[0]
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
    });
  };

  // Param form
  const [paramsFormDisabled, setParamsFormDisabled] = useState(true);
  const [customParams, setCustomParams] = useState([]);
  const [showParamsModal, setShowParamsModal] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setParamsFormDisabled(true);
    const data = customParams.map((param) =>
      param.value === INHERIT ? { name: param.name, value: null } : param
    );
    callVariantParams({
      id: id,
      data: data,
      onSuccess: () => {
        setProofs([]);
        createProof({
          id: id,
          onSuccess: (res) => {
            setProofs(res.results);
            setProofsError(null);
            setProofType(res.type);
          },
          onError: (error) => {
            setProofs([]);
            setProofsError(error);
            setProofType(null);
          },
        });
        mutate(variantParamsCacheKey);
        updateVariant();
        setShowParamsModal(false);
      },
      onError: (error) => {
        setParamsFormDisabled(false);
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-update-parameters")}
              errorText={
                !!error && !!error.length && !!error[0]
                  ? error[0]
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
    });
  };

  const cancelParamsEdit = () => {
    setParamsFormDisabled(true);
    setShowParamsModal(false);
    !!variantParams &&
      !!variantParams.length &&
      setCustomParams(
        variantParams.map((param) => ({
          name: param.name,
          value: !!param.parameterValue ? param.parameterValue : INHERIT,
        }))
      );
  };

  // set custom params
  useEffect(() => {
    !!variantParams &&
      !!variantParams.length &&
      setCustomParams(
        variantParams.map((param) => ({
          name: param.name,
          value: !!param.parameterValue ? param.parameterValue : INHERIT,
        }))
      );
  }, [variantParams]);

  const handleFullScreen = () => {
    setViewFullScreen((curr) => {
      if (!curr) {
        document
          .getElementsByTagName("body")?.[0]
          ?.classList.add("overflow-hidden");
      } else {
        document
          .getElementsByTagName("body")?.[0]
          ?.classList.remove("overflow-hidden");
      }

      return !curr;
    });
  };

  const handleFlip = () => setFlipPosition((prev) => (prev < 3 ? prev + 1 : 0));
  const resetFlip = () => setFlipPosition(0);

  return (
    <Layout
      main={
        !!variantIsLoading ? (
          <Card shadow title={`${t("ui.Variant")} ${id}`}>
            <Loader
              children={
                <div>
                  <h3>{t("ui.One-moment-please")}</h3>
                  <p>{t("ui.A-proof-is-being-generated")}</p>
                </div>
              }
              style={{ maxHeight: "65vh", minHeight: "50vh" }}
            />
          </Card>
        ) : !!variantIsError ? (
          <Card shadow title={`${t("ui.Variant")} ${id}`}>
            <Error errorObj={variantIsError} />
          </Card>
        ) : !!variant ? (
          <>
            <SCard
              title={variant.name}
              shadow
              overflowHidden
              icon={
                variant.kind === "dm" ? <GiFoldedPaper /> : <BsEnvelopeAtFill />
              }
              noRadius={!!viewFullScreen}
              stretchFullScreen={!!viewFullScreen}
              noMargin={!!viewFullScreen}
              noPadding={!!viewFullScreen}
              headerRight={
                <div
                  style={{ display: "flex", gap: ".4em" }}
                  className="hide-btn-labels"
                >
                  <Button
                    secondary={!viewFullScreen}
                    primary={!!viewFullScreen}
                    square
                    onClick={handleFullScreen}
                    small
                    className="fullscreen-btn"
                    title={
                      !viewFullScreen
                        ? t("ui.View-fullscreen")
                        : t("ui.Exit-fullscreen-view")
                    }
                  >
                    {!!viewFullScreen ? (
                      <AiOutlineFullscreenExit />
                    ) : (
                      <AiOutlineFullscreen />
                    )}
                  </Button>
                  {!variantParamsIsError ? (
                    <Button
                      disabled={
                        !paramsFormDisabled ||
                        variantParamsIsLoading ||
                        variantParamsIsError ||
                        (variantParams && !variantParams.length)
                      }
                      secondary
                      onClick={() => setShowParamsModal(true)}
                      small
                    >
                      {icons.edit}{" "}
                      <span className="btn-label">{t("my-account.Edit")}</span>
                    </Button>
                  ) : null}
                  {showParamsModal ? (
                    <EditParamsModal
                      variantParams={variantParams}
                      paramsFormDisabled={paramsFormDisabled}
                      setParamsFormDisabled={setParamsFormDisabled}
                      customParams={customParams}
                      setCustomParams={setCustomParams}
                      cancelParamsEdit={cancelParamsEdit}
                      onSubmit={onSubmit}
                      small
                    />
                  ) : null}
                  {showCustomerApproval ? (
                    <Button
                      disabled={
                        variant.customerApproval.approved || !paramsFormDisabled
                      }
                      primary
                      onClick={() => approveVariant(variant.id)}
                      small
                    >
                      {icons.check}{" "}
                      <span className="btn-label">
                        {variant.customerApproval.approved
                          ? t("ui.Approved")
                          : t("ui.Approve")}
                      </span>
                    </Button>
                  ) : null}
                </div>
              }
            >
              <SVariant>
                {viewFullScreen ? null : (
                  <div className="v-toolbar">
                    <Breadcrumbs
                      noMargin
                      pageTitle={variant.name}
                      pathArray={
                        variant.customerApproval.approved
                          ? [
                              {
                                title: t("ui.Variants"),
                              },
                              {
                                url: "/variants/approved",
                                title: t("ui.Approved-variants"),
                              },
                            ]
                          : [
                              {
                                title: t("ui.Variants"),
                              },
                              {
                                url: "/variants/to-approve",
                                title: t("ui.Variants-to-approve"),
                              },
                            ]
                      }
                    />

                    {/* <div className="btn-wrap">
                      {!variantParamsIsError ? (
                        <Button
                          disabled={
                            !paramsFormDisabled ||
                            variantParamsIsLoading ||
                            variantParamsIsError ||
                            (variantParams && !variantParams.length)
                          }
                          secondary
                          onClick={() => setShowParamsModal(true)}
                        >
                          {icons.edit} <span>{t("my-account.Edit")}</span>
                        </Button>
                      ) : null}
                      {showParamsModal ? (
                        <EditParamsModal
                          variantParams={variantParams}
                          paramsFormDisabled={paramsFormDisabled}
                          setParamsFormDisabled={setParamsFormDisabled}
                          customParams={customParams}
                          setCustomParams={setCustomParams}
                          cancelParamsEdit={cancelParamsEdit}
                          onSubmit={onSubmit}
                        />
                      ) : null}
                      {showCustomerApproval ? (
                        <Button
                          disabled={
                            variant.customerApproval.approved ||
                            !paramsFormDisabled
                          }
                          primary
                          onClick={() => approveVariant(variant.id)}
                        >
                          {icons.check}{" "}
                          <span>
                            {variant.customerApproval.approved
                              ? t("ui.Approved")
                              : t("ui.Approve")}
                          </span>
                        </Button>
                      ) : null}
                    </div> */}
                  </div>
                )}
                {!!proofs.length ? (
                  proofType === "edm" ? (
                    !!proofs[0].urls &&
                    !!proofs[0].urls.length &&
                    !!proofs[0].documentId ? (
                      <EdmPreview
                        url={proofs[0].urls[0]}
                        docId={proofs[0].documentId}
                        viewFullScreen={viewFullScreen}
                        handleFullScreen={handleFullScreen}
                      />
                    ) : null
                  ) : (
                    <div>
                      <div className="carousel-wrapper">
                        <VariantCarousel
                          proofs={proofs}
                          proofType={proofType}
                          viewFullScreen={viewFullScreen}
                          handleFullScreen={handleFullScreen}
                          flipPosition={flipPosition}
                          handleFlip={handleFlip}
                          resetFlip={resetFlip}
                        />
                      </div>
                    </div>
                  )
                ) : proofsError ? (
                  <div className="error-wrap">
                    <Error
                      errorMessage={t("error.Failed-to-create-proof")}
                      details={proofsError}
                      children={
                        <p style={{ paddingTop: ".2em" }}>
                          <Button
                            as={Link}
                            $link
                            to="/variants"
                            className="icon-link"
                          >
                            {icons.chevronLeft} {t("ui.Go-back")}
                          </Button>
                        </p>
                      }
                      errorObj={proofsError}
                    />
                  </div>
                ) : (
                  <div className="error-wrap">
                    <Loader
                      children={
                        <div>
                          <h3>{t("ui.One-moment-please")}</h3>
                          <p>{t("ui.A-proof-is-being-generated")}</p>
                        </div>
                      }
                      style={{ maxHeight: "65vh", minHeight: "50vh" }}
                    />
                  </div>
                )}
              </SVariant>
            </SCard>
            {viewFullScreen ? null : (
              <div className="row-wrap">
                <Row>
                  <Col md={4}>
                    <Card
                      title={t("ui.Linked-program")}
                      children={
                        <SCampaignBlock>
                          {!!variant.program.thumbnailFileId && (
                            <div className="img-wrap">
                              <ImageBox
                                params={{
                                  Width: 70,
                                  Height: 70,
                                }}
                                id={variant.program.thumbnailFileId}
                                boxHeight="100%"
                              />
                            </div>
                          )}
                          <div className="text-wrap">
                            <h3>{variant.program.name || "..."}</h3>
                            <p>
                              {variant.mailMoment.name}{" "}
                              <Badge>{variant.mailMoment.code}</Badge>
                            </p>
                            <Link
                              to={
                                variant.program.type === "cycle"
                                  ? `/cycle/${variant.program.id}`
                                  : `/campaign/${variant.program.id}`
                              }
                            >
                              <Button link>
                                <span>{t("ui.View")}</span>
                                {icons.chevronRight}
                              </Button>
                            </Link>
                          </div>
                        </SCampaignBlock>
                      }
                      icon={
                        variant.program.type === "cycle" ? (
                          <CycleIcon />
                        ) : (
                          icons.campaigns
                        )
                      }
                      shadow
                    />
                  </Col>
                  <Col md={4}>
                    <Card
                      title={t("menu.FAQ")}
                      children={<FAQList />}
                      icon={icons.faq}
                      withBg
                      shadow
                    />
                  </Col>
                  <Col md={4}>
                    <Card
                      title={t("ui.Contact")}
                      children={<ContactText />}
                      icon={icons.contact}
                      shadow
                    />
                  </Col>
                </Row>
              </div>
            )}
          </>
        ) : null
      }
      pageTitle={!!variant ? variant.name : t("ui.Variant") + " " + id}
      fullScreen={viewFullScreen}
    />
  );
};

export default Variant;
