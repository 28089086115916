import React from "react";
import MailMoment from "../proofing/MailMoment";
import { Col } from "styled-bootstrap-grid";
import CardList from "../CardList";

const OrderMailMoments = ({
  mailMoments = [],
  orderVariants = [],
  orderVariantIds = [],
  selectedVIndex = 0,
  setSelectedVIndex,
  onNext,
  updateOrderVariants,
}) => {
  const activeMM = mailMoments.filter((mm) => !!mm.active);
  const orderedVars = orderVariantIds
    .map((id) => orderVariants.find((ov) => ov?.id === id))
    .filter((ov) => !!ov);
  const orderedMM = orderedVars.map((ov) =>
    activeMM.find((mm) => mm?.id === ov?.mailMoment?.id)
  );
  const uniqueOrderedMM = [...new Set(orderedMM)];
  const orderedMMwithVariants = uniqueOrderedMM.map((mm) => ({
    ...mm,
    variants: orderedVars.filter((ov) => ov?.mailMoment?.id === mm?.id),
  }));

  return (
    <CardList className="cardlist-w-margin">
      {orderedMMwithVariants.map((mm, i) => (
        <Col
          key={`${mm.id}-${i}-omm`}
          xs={12}
          onClick={() => setSelectedVIndex(i)}
        >
          <MailMoment
            {...mm}
            isSelected={i === selectedVIndex}
            onNext={
              i === orderedMMwithVariants.length - 1
                ? onNext
                : () => setSelectedVIndex(i + 1)
            }
            updateParentVariants={updateOrderVariants}
          />
        </Col>
      ))}
    </CardList>
  );
};

export default OrderMailMoments;
