import React from "react";
import logoEssilor from "../assets/img/essilor/logo-essilor.svg";
import logoOogwereld from "../assets/img/oogwereld/logo-oogwereld.png";
import logoPortalUK from "../assets/img/portalUK/logo-portalUK.png";
import logoPortalES from "../assets/img/tematica/logo-portalES.png";
import logoVolvo from "../assets/img/volvo/logo-volvo.png";
import logoOMP from "../assets/img/OMP/logo-omp.png";
import logoBikeNL from "../assets/img/bikeNL/logo-bikeNL.png";
import logoAcousticsNL from "../assets/img/acousticsNL/logo-acousticsNL.png";
import logoRodenstock from "../assets/img/rodenstock/logo-rodenstock.png";
import logoEyecare from "../assets/img/eyecare/logo-eyecare.png";
import logoEGSDE from "../assets/img/EGSDE/logo-egsde.png";
import Layout from "./parts/Layout";
import Card from "./Card";
import styled from "styled-components";

const SLogosPage = styled.div`
  img {
    display: block;
    width: auto;
    height: auto;
    margin: 2em auto;
  }
`;

const GenerateLogos = () => {
  const logos = [
    logoEssilor,
    logoOogwereld,
    logoPortalUK,
    logoPortalES,
    logoVolvo,
    logoOMP,
    logoBikeNL,
    logoAcousticsNL,
    logoRodenstock,
    logoEyecare,
    logoEGSDE,
  ];
  return (
    <Layout
      main={
        <Card shadow title={"Customer logos"}>
          <SLogosPage>
            {logos.map((logo, i) => (
              <img key={`logo${i}`} src={logo} alt="Customer logo" />
            ))}
          </SLogosPage>
        </Card>
      }
      pageTitle={"Customer logos"}
    />
  );
};

export default GenerateLogos;
