import useSWR from "swr";
import fetcher from "./fetcher";
import apiConfig from "../apiConfig";

const { reporting } = apiConfig;

const useViews = ({ chartType = "", pageSize = 100 }) => {
  const lang = localStorage.getItem("language");
  const { data, error } = useSWR(
    `${reporting}/views${`?chartType=${chartType}`}&pageSize=${pageSize}${
      lang ? `&language=${lang.split("-")?.[0]}` : ""
    }`,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useViews;
