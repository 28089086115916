import React from "react";
import { GlobalAlert } from "@zendeskgarden/react-notifications";
import { Col, Row } from "styled-bootstrap-grid";
import styled from "styled-components";
import config from "../config";
import { useTranslation } from "react-i18next";
import { BsExclamationTriangleFill } from "react-icons/bs";

const SWarning = styled(GlobalAlert)`
  color: rgba(0, 0, 0, 0.6);
  background-color: #f9dc41;
  border-radius: 6px;
  padding: 0.8em;
  position: relative;
  line-height: 1.4;
  > svg {
    display: none;
  }
  .close-btn {
    margin: 0;
  }
  .alert-inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0.4em 0;
  }
  .alert-img {
    color: rgba(0, 0, 0, 0.2);
    width: auto;
    height: 100%;
    max-width: 55px;
    margin: 0 1em;
    flex-shrink: 0;
  }
  .alert-title {
    margin-right: 0;
    color: inherit;
    margin-bottom: 0.2em;
  }
  &:before {
    content: "";
    position: absolute;
    width: 6px;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    background-image: linear-gradient(
      45deg,
      #000000 25%,
      #ffc400 25%,
      #ffc400 50%,
      #000000 50%,
      #000000 75%,
      #ffc400 75%,
      #ffc400 100%
    );
    background-size: 35px 35px;
  }
  &:before {
    left: 0;
  }
  .date-str {
    display: inline-block;
  }
  @media (max-width: 575px) {
    margin-bottom: 0.6em;
    font-size: 95%;
    .alert-img {
      margin-right: 1.4em;
    }
  }
`;

export const MaintenanceMessageContent = ({
  showStartDate = true,
  isDiscontinued,
}) => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language || "nl-NL";

  const formatDate = (
    dateStr,
    options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }
  ) => new Date(Date.parse(dateStr)).toLocaleString(lang, options);

  const start = config?.uiSettings?.maintenanceMessage?.start;
  const end = config?.uiSettings?.maintenanceMessage?.end;

  const startDateTime =
    start &&
    `${formatDate(start, {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    })}, 
    ${formatDate(start, {
      hour: "numeric",
      minute: "numeric",
    })}`;

  const endDate =
    end &&
    `${formatDate(end, {
      weekday: "long",
      month: "long",
      day: "numeric",
    })}, ${formatDate(end, {
      hour: "numeric",
      minute: "numeric",
    })}`;

  return (
    <span>
      <span className="msg-wrap">
        {showStartDate ? (
          <span>
            {t("ui.Upcoming-maintenance-message", {
              portalName: process.env.REACT_APP_NAME,
            })}{" "}
            {t("ui.between")}{" "}
            <strong className="date-str">{startDateTime}</strong> {t("ui.and")}{" "}
          </span>
        ) : (
          <span>
            {t("ui.Portal-unavailable-message", {
              portalName: process.env.REACT_APP_NAME,
            })}{" "}
            {t("ui.until")}
          </span>
        )}{" "}
      </span>
      {isDiscontinued ? null : <strong className="date-str">{endDate}</strong>}
    </span>
  );
};

const MaintenanceMessage = ({ showMessage, hideMessage }) => {
  const { t } = useTranslation("common");
  const isDiscontinued = config?.uiSettings?.isDiscontinued === true;

  return config?.uiSettings?.maintenanceMessage?.start &&
    config?.uiSettings?.maintenanceMessage?.end ? (
    showMessage ? (
      <Row>
        <Col col={12}>
          <SWarning type="warning" className="margin-bottom fadeInDown">
            <GlobalAlert.Content className="alert-inner">
              <BsExclamationTriangleFill className="alert-img" />
              <MaintenanceMessageContent isDiscontinued={isDiscontinued} />
            </GlobalAlert.Content>
            <GlobalAlert.Close
              aria-label={t("ui.Ok-I-understand")}
              title={t("ui.Ok-I-understand")}
              className="close-btn"
              onClick={hideMessage}
            />
          </SWarning>
        </Col>
      </Row>
    ) : null
  ) : null;
};

export default MaintenanceMessage;
