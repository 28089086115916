import React from "react";
import Link from "./Link";
import logoEssilor from "../assets/img/essilor/logo-essilor.svg";
import logoOogwereld from "../assets/img/oogwereld/logo-oogwereld.png";
import logoPortalUK from "../assets/img/portalUK/logo-portalUK.png";
import logoPortalES from "../assets/img/tematica/logo-portalES.png";
import logoVolvo from "../assets/img/volvo/logo-volvo.png";
import logoOMP from "../assets/img/OMP/logo-omp.png";
import logoBikeNL from "../assets/img/bikeNL/logo-bikeNL.png";
import logoAcousticsNL from "../assets/img/acousticsNL/logo-acousticsNL.png";
import logoRodenstock from "../assets/img/rodenstock/logo-rodenstock.png";
import logoEyecare from "../assets/img/eyecare/logo-eyecare.png";
import logoEGSDE from "../assets/img/EGSDE/logo-egsde.png";
import {
  ESSILORNL,
  ESSILORBE,
  OOGWERELDNL,
  OOGWERELDBE,
  PORTALUK,
  TEMATICA,
  VOLVO,
  OMP,
  BIKENL,
  ACOUSTICSNL,
  RODENSTOCKDE,
  EYECARENL,
  EGSDE,
  TEST,
  EYECAREBE,
} from "../constants";
import config from "../config";

const selectLogo = (clientName) => {
  switch (clientName) {
    case TEST:
    case ESSILORNL:
      return logoEssilor;
    case ESSILORBE:
      return logoEssilor;
    case RODENSTOCKDE:
      return logoRodenstock;
    case OOGWERELDNL:
    case OOGWERELDBE:
      return logoOogwereld;
    case PORTALUK:
      return logoPortalUK;
    case TEMATICA:
      return logoPortalES;
    case VOLVO:
      return logoVolvo;
    case OMP:
      return logoOMP;
    case BIKENL:
      return logoBikeNL;
    case ACOUSTICSNL:
      return logoAcousticsNL;
    case EYECARENL:
    case EYECAREBE:
      return logoEyecare;
    case EGSDE:
      return logoEGSDE;
    default:
      return logoPortalUK;
  }
};

const Logo = ({ withLink = true, fixed = !withLink }) => {
  const logoImg = selectLogo(config.clientCode);
  return withLink ? (
    <Link to="/" className="logo-link">
      <img
        src={logoImg}
        alt="Customer logo"
        className={`logo-img ${fixed ? "logo-fixed" : ""} logo-custom-${
          config.clientCode
        }`}
      />
    </Link>
  ) : (
    <img
      src={logoImg}
      alt="Customer logo"
      className={`logo-img ${fixed ? "logo-fixed" : ""} logo-custom-${
        config.clientCode
      }`}
    />
  );
};

export default Logo;
